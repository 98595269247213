:root ._dsp-flex{display:flex;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._ai-center{align-items:center;}
:root ._columnGap-t-space-1{column-gap:var(--t-space-1);}
:root ._jc-center{justify-content:center;}
:root ._gap-3px{gap:3px;}
:root ._w-18px{width:18px;}
:root ._btlr-4px{border-top-left-radius:4px;}
:root ._btrr-4px{border-top-right-radius:4px;}
:root ._bbrr-4px{border-bottom-right-radius:4px;}
:root ._bblr-4px{border-bottom-left-radius:4px;}
:root ._pt-5px{padding-top:5px;}
:root ._bg-rgba25525522063358324{background-color:rgba(255, 255, 255, 0.7);}
:root ._bg-transparent{background-color:transparent;}
:root ._pb-4px{padding-bottom:4px;}
:root ._pb-0px{padding-bottom:0px;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-row{flex-direction:row;}