:root ._dsp-flex{display:flex;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-row{flex-direction:row;}
:root ._jc-space-betwe3241{justify-content:space-between;}
:root ._ai-center{align-items:center;}
:root ._bg-FFFFFF35{background-color:#FFFFFF;}
:root ._pt-8px{padding-top:8px;}
:root ._pb-8px{padding-bottom:8px;}
:root ._ox-hidden{overflow-x:hidden;}
:root ._oy-hidden{overflow-y:hidden;}