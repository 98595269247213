:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-row{flex-direction:row;}
:root ._pr-5px{padding-right:5px;}
:root ._pl-5px{padding-left:5px;}
:root ._pt-5px{padding-top:5px;}
:root ._pb-5px{padding-bottom:5px;}
:root ._mt-auto{margin-top:auto;}
:root ._mb-auto{margin-bottom:auto;}