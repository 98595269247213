:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-row{flex-direction:row;}
:root ._fw-wrap{flex-wrap:wrap;}
:root ._pr-14px{padding-right:14px;}
:root ._pl-14px{padding-left:14px;}
:root ._pt-8px{padding-top:8px;}
:root ._pb-8px{padding-bottom:8px;}
:root ._gap-8px{gap:8px;}
:root ._w-10037{width:100%;}
:root ._btc-e9e9ea35{border-top-color:#e9e9ea;}
:root ._btw-1px{border-top-width:1px;}
:root ._bts-solid{border-top-style:solid;}