:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._aspectRatio-0--87894736841478192584{aspect-ratio:0.8789473684210526;}
:root ._w-10037{width:100%;}
:root ._mb-8px{margin-bottom:8px;}
:root ._pos-absolute{position:absolute;}
:root ._jc-flex-end{justify-content:flex-end;}
:root ._ai-flex-end{align-items:flex-end;}
:root ._zi-2{z-index:2;}
:root ._b-5px{bottom:5px;}
:root ._r-5px{right:5px;}
:root ._gap-3px{gap:3px;}
:root ._w-18px{width:18px;}
:root ._l-5px{left:5px;}
:root ._jc-flex-start{justify-content:flex-start;}
:root ._ai-flex-start{align-items:flex-start;}
:root ._t-4px{top:4px;}