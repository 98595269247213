:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-10037{min-height:100%;}
:root ._miw-10037{min-width:100%;}
:root ._fs-0{flex-shrink:0;}
:root ._pe-none{pointer-events:none !important;}
:root ._bg-0000001035{background-color:#00000010;}
:root ._w-10037{width:100%;}
:root ._h-10037{height:100%;}
:root ._zi-900{z-index:900;}