:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fd-column{flex-direction:column;}
:root ._fg-1{flex-grow:1;}
:root ._fs-1{flex-shrink:1;}
:root ._maw-80px{max-width:80px;}
:root ._fs-0{flex-shrink:0;}
:root ._ai-center{align-items:center;}
:root ._jc-center{justify-content:center;}
:root ._btlr-10px{border-top-left-radius:10px;}
:root ._btrr-10px{border-top-right-radius:10px;}
:root ._bbrr-10px{border-bottom-right-radius:10px;}
:root ._bblr-10px{border-bottom-left-radius:10px;}
:root ._pt-0px{padding-top:0px;}
:root ._pr-0px{padding-right:0px;}
:root ._pb-0px{padding-bottom:0px;}
:root ._pl-0px{padding-left:0px;}
:root ._btw-2px{border-top-width:2px;}
:root ._brw-2px{border-right-width:2px;}
:root ._bbw-2px{border-bottom-width:2px;}
:root ._blw-2px{border-left-width:2px;}
:root ._btc-c-color-Gra3801{border-top-color:var(--c-color-Gray2);}
:root ._brc-c-color-Gra3801{border-right-color:var(--c-color-Gray2);}
:root ._bbc-c-color-Gra3801{border-bottom-color:var(--c-color-Gray2);}
:root ._blc-c-color-Gra3801{border-left-color:var(--c-color-Gray2);}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bts-solid{border-top-style:solid;}
:root ._bls-solid{border-left-style:solid;}
:root ._brs-solid{border-right-style:solid;}
:root ._btw-0px{border-top-width:0px;}
:root ._brw-0px{border-right-width:0px;}
:root ._bbw-0px{border-bottom-width:0px;}
:root ._blw-0px{border-left-width:0px;}
:root ._btc-c-color-pur1319873852{border-top-color:var(--c-color-pureWhite);}
:root ._brc-c-color-pur1319873852{border-right-color:var(--c-color-pureWhite);}
:root ._bbc-c-color-pur1319873852{border-bottom-color:var(--c-color-pureWhite);}
:root ._blc-c-color-pur1319873852{border-left-color:var(--c-color-pureWhite);}