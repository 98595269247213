:root ._dsp-flex{display:flex;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fd-row{flex-direction:row;}
:root ._jc-space-betwe3241{justify-content:space-between;}
:root ._ai-center{align-items:center;}
:root ._fs-1{flex-shrink:1;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._pr-10px{padding-right:10px;}
:root ._pl-10px{padding-left:10px;}
:root ._fs-0{flex-shrink:0;}
:root ._mih-30px{min-height:30px;}
:root ._fw-wrap{flex-wrap:wrap;}
:root ._columnGap-6px{column-gap:6px;}
:root ._btw-1px{border-top-width:1px;}
:root ._brw-1px{border-right-width:1px;}
:root ._bbw-1px{border-bottom-width:1px;}
:root ._blw-1px{border-left-width:1px;}
:root ._btlr-5px{border-top-left-radius:5px;}
:root ._btrr-5px{border-top-right-radius:5px;}
:root ._bbrr-5px{border-bottom-right-radius:5px;}
:root ._bblr-5px{border-bottom-left-radius:5px;}
:root ._pt-4px{padding-top:4px;}
:root ._pr-4px{padding-right:4px;}
:root ._pb-4px{padding-bottom:4px;}
:root ._pl-4px{padding-left:4px;}
:root ._btc-7F7F7F35{border-top-color:#7F7F7F;}
:root ._brc-7F7F7F35{border-right-color:#7F7F7F;}
:root ._bbc-7F7F7F35{border-bottom-color:#7F7F7F;}
:root ._blc-7F7F7F35{border-left-color:#7F7F7F;}
:root ._r-5px{right:5px;}
:root ._pos-absolute{position:absolute;}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bts-solid{border-top-style:solid;}
:root ._bls-solid{border-left-style:solid;}
:root ._brs-solid{border-right-style:solid;}