:root ._dsp-flex{display:flex;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-row{flex-direction:row;}
:root ._btw-1px{border-top-width:1px;}
:root ._brw-1px{border-right-width:1px;}
:root ._bbw-1px{border-bottom-width:1px;}
:root ._blw-1px{border-left-width:1px;}
:root ._btc-c-color-neu1067180756{border-top-color:var(--c-color-neutral500);}
:root ._brc-c-color-neu1067180756{border-right-color:var(--c-color-neutral500);}
:root ._bbc-c-color-neu1067180756{border-bottom-color:var(--c-color-neutral500);}
:root ._blc-c-color-neu1067180756{border-left-color:var(--c-color-neutral500);}
:root ._btlr-11px{border-top-left-radius:11px;}
:root ._btrr-11px{border-top-right-radius:11px;}
:root ._bbrr-11px{border-bottom-right-radius:11px;}
:root ._bblr-11px{border-bottom-left-radius:11px;}
:root ._pt-8px{padding-top:8px;}
:root ._pb-8px{padding-bottom:8px;}
:root ._pr-10px{padding-right:10px;}
:root ._pl-10px{padding-left:10px;}
:root ._ai-center{align-items:center;}
:root ._gap-5px{gap:5px;}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bts-solid{border-top-style:solid;}
:root ._bls-solid{border-left-style:solid;}
:root ._brs-solid{border-right-style:solid;}