:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._w-10037{width:100%;}
:root ._dsp-flex{display:flex;}
:root ._jc-center{justify-content:center;}
:root ._ai-center{align-items:center;}
:root ._ai-stretch{align-items:stretch;}
:root ._mih-t-space-16{min-height:var(--t-space-16);}
:root ._miw-t-space-16{min-width:var(--t-space-16);}
:root ._pe-none{pointer-events:none !important;}
:root ._w-t-space-16{width:var(--t-space-16);}
:root ._h-t-space-16{height:var(--t-space-16);}
:root ._mih-t-space-1{min-height:var(--t-space-1);}
:root ._miw-t-space-1{min-width:var(--t-space-1);}
:root ._w-t-space-1{width:var(--t-space-1);}
:root ._h-t-space-1{height:var(--t-space-1);}