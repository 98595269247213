:root ._dsp-flex{display:flex;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-row{flex-direction:row;}
:root ._ai-center{align-items:center;}
:root ._gap-15px{gap:15px;}
:root ._pr-15px{padding-right:15px;}
:root ._pl-15px{padding-left:15px;}
:root ._pt-10px{padding-top:10px;}
:root ._pb-10px{padding-bottom:10px;}