:root ._dsp-flex{display:flex;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._ai-center{align-items:center;}
:root ._w-2537{width:25%;}
:root ._rowGap-4px{row-gap:4px;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-row{flex-direction:row;}
:root ._btlr-9px{border-top-left-radius:9px;}
:root ._btrr-9px{border-top-right-radius:9px;}
:root ._bbrr-9px{border-bottom-right-radius:9px;}
:root ._bblr-9px{border-bottom-left-radius:9px;}
:root ._btc-c-color-pri258286010{border-top-color:var(--c-color-primary1000);}
:root ._brc-c-color-pri258286010{border-right-color:var(--c-color-primary1000);}
:root ._bbc-c-color-pri258286010{border-bottom-color:var(--c-color-primary1000);}
:root ._blc-c-color-pri258286010{border-left-color:var(--c-color-primary1000);}
:root ._btw-2px{border-top-width:2px;}
:root ._brw-2px{border-right-width:2px;}
:root ._bbw-2px{border-bottom-width:2px;}
:root ._blw-2px{border-left-width:2px;}
:root ._pt-4px{padding-top:4px;}
:root ._pr-4px{padding-right:4px;}
:root ._pb-4px{padding-bottom:4px;}
:root ._pl-4px{padding-left:4px;}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bts-solid{border-top-style:solid;}
:root ._bls-solid{border-left-style:solid;}
:root ._brs-solid{border-right-style:solid;}
:root ._btc-transparent{border-top-color:transparent;}
:root ._brc-transparent{border-right-color:transparent;}
:root ._bbc-transparent{border-bottom-color:transparent;}
:root ._blc-transparent{border-left-color:transparent;}