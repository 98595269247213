:root ._dsp-flex{display:flex;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-row{flex-direction:row;}
:root ._btc-c-color-neu1067182678{border-top-color:var(--c-color-neutral300);}
:root ._brc-c-color-neu1067182678{border-right-color:var(--c-color-neutral300);}
:root ._bbc-c-color-neu1067182678{border-bottom-color:var(--c-color-neutral300);}
:root ._blc-c-color-neu1067182678{border-left-color:var(--c-color-neutral300);}
:root ._btw-1px{border-top-width:1px;}
:root ._brw-1px{border-right-width:1px;}
:root ._bbw-1px{border-bottom-width:1px;}
:root ._blw-1px{border-left-width:1px;}
:root ._pr-10px{padding-right:10px;}
:root ._pl-10px{padding-left:10px;}
:root ._pt-5px{padding-top:5px;}
:root ._pb-5px{padding-bottom:5px;}
:root ._btlr-8px{border-top-left-radius:8px;}
:root ._btrr-8px{border-top-right-radius:8px;}
:root ._bbrr-8px{border-bottom-right-radius:8px;}
:root ._bblr-8px{border-bottom-left-radius:8px;}
:root ._bg-transparent{background-color:transparent;}
:root ._ai-center{align-items:center;}
:root ._jc-center{justify-content:center;}
:root ._gap-4px{gap:4px;}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bts-solid{border-top-style:solid;}
:root ._bls-solid{border-left-style:solid;}
:root ._brs-solid{border-right-style:solid;}
:root ._mih-14px{min-height:14px;}
:root ._miw-14px{min-width:14px;}
:root ._fd-column{flex-direction:column;}
:root ._btlr-100000px{border-top-left-radius:100000px;}
:root ._btrr-100000px{border-top-right-radius:100000px;}
:root ._bbrr-100000px{border-bottom-right-radius:100000px;}
:root ._bblr-100000px{border-bottom-left-radius:100000px;}
:root ._pt-0px{padding-top:0px;}
:root ._pr-0px{padding-right:0px;}
:root ._pb-0px{padding-bottom:0px;}
:root ._pl-0px{padding-left:0px;}
:root ._w-14px{width:14px;}
:root ._h-14px{height:14px;}
:root ._maw-14px{max-width:14px;}
:root ._mah-14px{max-height:14px;}
:root ._pos-absolute{position:absolute;}
:root ._r--6px{right:-6px;}
:root ._t--6px{top:-6px;}
:root ._bg-c-color-neu1067182678{background-color:var(--c-color-neutral300);}